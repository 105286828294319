import axios from 'axios'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL + '/operation',
  timeout: 1200000 // 请求超时时间
})

const errorMessage = (message) => {
  alert(message)
}
// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    const status = error.response.status
    if (status === 401) {
      errorMessage(data.msg)
    } else {
      errorMessage(` ${data.msg || 'error'}`)
    }
  } else {
    errorMessage(error.message || '请求出错，请稍后重试！')
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  config.headers.Accept = `application/json`
  config.headers.Authorization = 'Bearer Authorization'
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  return response.data
}, errorHandler)

export default request
