<template>
    <div class="record" v-if="successShow">
        <div class="bg" @click="successShow=false"></div>
        <div class="lottery-box" v-show="clientclockIn.receive_prize!=5">
            <div class="end" @click="hide">×</div>
            <div class="top">
              <p>恭喜你打卡成功{{taskData.receive_prize}}</p>
            </div>
            <div class="content" style="text-align: center;">
              <div class="clock_time" v-if="type==0">完成打卡时间：<span>{{ taskData.day }}</span></div>
              <div class="clock_tip_a" v-if="type==1">你已经完成{{ clockInType }}打卡{{ taskData.day_count }}天任务</div>
              <img :src="employee_qrcode" class="qr_code" alt=""/>
              <div class="clock_tips" v-if="type==0 && taskData.status==0">真棒!你已经{{ clockInType }}打卡{{ taskData.day_count }}天</div>
              <div class="clock_tips" v-if="type==0 && taskData.status==1">真棒!你已经完成{{ clockInType }}打卡{{ taskData.day_count }}天任务</div>
              <div class="clock_tips" v-if="type==0 && taskData.status==2">真棒!你已经完成所有打卡任务</div>
              <div class="clock_tips" v-if="type==0 && (taskData.status==0 || taskData.status==1)">继续打卡完成其他任务吧</div>
              <div class="clock_tips">记得添加客服领取奖励哦~</div>
            </div>
        
        </div>
        <!-- 现场核销弹窗 -->
        <div class="popBox" v-show="clientclockIn.receive_prize==5">
              <div class="title">恭喜你打卡成功</div>
              <div class="boxTime">
                   <div class="timeClass" v-if="type==0">完成打卡时间：<span class="timeDay">{{ taskData.day }}</span></div>
                   <div class="timeClass" v-if="type==1">你已经完成{{ clockInType }}打卡{{ taskData.day_count }}天任务</div>
              </div>
              <div class="boxQc" ref="qrcode"></div>
              <div class="tips">
                    <div v-if="type==0 && taskData.status==0">真棒!你已经{{ clockInType }}打卡{{ taskData.day_count }}天</div>
                    <div  v-if="type==0 && taskData.status==1">真棒!你已经完成{{ clockInType }}打卡{{ taskData.day_count }}天任务</div>
                    <div  v-if="type==0 && taskData.status==2">真棒!你已经完成所有打卡任务</div>
                    <div  v-if="type==0 && (taskData.status==0 || taskData.status==1)">继续打卡完成其他任务吧</div>
                    <div >记得添加客服领取奖励哦~</div>
              </div>
              <div class="closeBox" @click="hide">
                <img src="../../static/images/opoClose.png" alt="">
              </div>
        </div>
    </div>
</template>
<script>
import QRCode from 'qrcodejs2'
export default {
    data() {
        return {
          successShow: false,
          taskData:{},
          employee_qrcode:'',
          type:0,
          clockInType:'',
          clientclockIn:{}
        }
    },
    methods: {
        getNews(type,data,employee_qrcode,clockInType,clientclockIn) {
          this.type=type
          this.successShow = true
        //   if(this.type==0){
            this.taskData=data
            //现场核销，绘制二维码
            this.clientclockIn=clientclockIn
            if(clientclockIn&&clientclockIn.receive_prize==5){
                setTimeout(() => {
                    this.setQrcode()
                }, 500);
            }
        //   }
          this.employee_qrcode=employee_qrcode
          this.clockInType=(clockInType == 1) ? '连续' : '累计'
        },
        hide() {
            this.successShow = false
        },
         setQrcode(){
            new QRCode(this.$refs.qrcode,{
                text:this.clientclockIn.receive_code,
                width:140,
                height:140,
                // colorDark:'#333',//二维码颜色
                colorLight:'#fff',//二维码背景颜色
                correctLevel:QRCode.CorrectLevel.L//容错率,L/M/H
            })
        }
    }
}
</script>

<style scoped lang="less">
.record {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 100;

    .bg {
        width: 100vw;
        height: 100vh;
        background-color: #00000094;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
    }
    .lottery-box {
        width: 88vw;
        height: 300px;
        background-color: #ffffff;
        padding-bottom: 10px;
        border-radius: 18px;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        position: relative;
        z-index: 20;

        .top {
            text-align: center;
            width: 78vw;
            height: 30px;
            margin-top: 12px;

            p {
                color: #e3453a;
                font-size: 18px;
                font-weight: bold;
            }

            span {
                display: block;
                margin-top: -16px;
            }
        }

        .box {
            width: 78vw;
            height: 200px;

            .top-box {
                display: flex;
                justify-content: center;
                margin-top: 34px;

                .top-left {
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 30px;
                    border-right: 1px dashed #ff5636;
                }

                .top-right {
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 30px;
                }

                .numSpan {
                    font-size: 26px;
                    color: #ff5636;
                    margin: 0 4px;
                }
            }
        }

        .bottom {
            width: 78vw;
            height: 43px;
            display: block;
            margin-top: -10px;
            position: relative;
            z-index: 10;
            background: linear-gradient(to top, #f48744, #ffd38f);
            border-radius: 8px;

            .box {
                width: 75vw;
                height: 30px;
                background-color: #fff;
                border-radius: 8px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                padding: 16px;
            }
        }
    }
    .popBox{
        width: 100%;
        height: 402px;
        padding: 0 50px;
        background-image: url("../../static/images/popBack.png");
        background-size: 100% 100%;
        position: relative;
        z-index: 20;
        .title{
            font-size: 22px;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 31px;
            text-shadow: 0px 1px 1px #CC2800;
            text-align: center;
            margin-top: 8px;
        }
        .boxTime{
            margin-top: 45px;
            .timeClass{
                font-size: 14px;
                font-weight: 200;
                color: #333333;
                line-height: 21px;
                text-align: center;
            }
            .timeDay{
                font-size: 14px;
                font-weight: 200;
                color: #FF562D;
                line-height: 21px;
            }
        }
        .boxQc{
            margin: 0 auto;
            margin-top: 25px;
            background: #FFFFFF;
            padding: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 150px;
            height: 150px;
        }
        .tips{
            margin-top: 25px;
            font-size: 12px;
            font-weight: 400;
            color: #333333;
            line-height: 21px;
            text-align: center;
        }
        .closeBox{
            position: absolute;
            z-index: 33;
            bottom:-35px;
            left: 50%;
            transform: translateX(-50%);
            img{
                width: 32px;
                height: 32px;
            }
        }
    }
}

.end {
    position: absolute;
    font-size: 24px;
    right: 12px;
}
.qr_code{
  width: 150px;
  height: 150px;
}
.clock_time{
  color: #100800;
  font-size: 15px;
}
.clock_time span{
  color: #EC7507;
}
.clock_tips{
  color: #433A31;
  font-size: 15px;
}
.clock_tip_a{
  color: #FAB34B;
  font-size: 17px;
  margin-top: -20px;
  margin-bottom: 10px;
}

</style>
