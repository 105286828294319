import { getJssdkConfig } from '@/api/wxconfig'

const wx = window.wx

// 获取微信注入参数
async function getConfigParam () {
  try { 
      var signLink = "";
      var ua = navigator.userAgent.toLowerCase();
      if (/iphone|ipad|ipod/.test(ua)) {
        signLink = localStorage.getItem("WxCodeUrl");
        if (!signLink) signLink = location.href;
      } else {
        signLink = location.href;
      }
    // const url = window.location.href.split('#')[0]
    const url = signLink
    const params = {
      url
    }
    const { data } = await getJssdkConfig(params)
    return data
  } catch (e) {
    console.log(e)
  }
}

const jsApiList = ['onMenuShareAppMessage', 'onMenuShareTimeline', 'onMenuShareQQ', 'onMenuShareWeibo', 'updateAppMessageShareData', 'updateTimelineShareData','openLocation','getLocation','onMenuShareQZone']
// wx.config
export function wxConfig (callbackFunction) {
  return new Promise((resolve, reject) => {
    getConfigParam().then(data => {
      const { appId, timestamp, nonceStr, signature } = data
      wx.config({
        debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
        appId: appId, // 必填，公众号的唯一标识
        timestamp: timestamp, // 必填，生成签名的时间戳
        nonceStr: nonceStr, // 必填，生成签名的随机串
        signature: signature,// 必填，签名
        jsApiList // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
      })
      wx.ready(function () {
        console.log('callbackFunction', callbackFunction)
        if (typeof (callbackFunction) == "function") {
          callbackFunction()
        } else {
          resolve()
        }
      })
      wx.error(function (res) {
        console.log('wx.config fail')
        reject(res)
      })
    }).catch(e => {
      reject(e)
    })
  })
}


